import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.math.trunc.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import moment from "moment";
import { i18n } from "~/plugins/translator";
import { hasValue } from "../common-helpers";
var PeriodTypeFilter = {
  MONTHLY: filter.monthYear,
  QUARTERLY: filter.quarter,
  YEARLY: filter.year
};
export var formatIdleTenancyRate = function formatIdleTenancyRate(series, periodType) {
  return {
    xaxis: {
      tickAmount: series.length < 10 ? series.length : 5,
      categories: series.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: i18n.t("PORTFOLIO_GRAPH_IDLE_TENANCY_RATE"),
      data: series.map(function (s) {
        return ((s.possibleDays - s.occupiedDays) / s.possibleDays * 100).toFixed(1);
      })
    }]
  };
};
export var formatIdleTenancyRateRent = function formatIdleTenancyRateRent(series, periodType) {
  var percentage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return {
    xaxis: {
      tickAmount: series.length < 10 ? series.length : 5,
      categories: series.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: i18n.t("PORTFOLIO_GRAPH_IDLE_TENANCY_RATE"),
      data: percentage ? series.map(function (s) {
        return (hasValue(s.lostRent) && hasValue(s.possibleRent) ? s.lostRent / s.possibleRent * 100 : 0).toFixed(1);
      }) : series.map(function (s) {
        return s.lostRent;
      })
    }]
  };
};
export var formatEvictionRate = function formatEvictionRate(series, periodType) {
  return {
    xaxis: {
      tickAmount: series.length < 10 ? series.length : 5,
      categories: series.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: i18n.t("PORTFOLIO_GRAPH_EVICTION_RATE"),
      data: series.map(function (s) {
        return (s.evictions / (s.totalTenancies + s.evictions) * 100).toFixed(1);
      })
    }]
  };
};
export var formatChurnRate = function formatChurnRate(idleTenancySeries, churnRateSeries, periodType) {
  return {
    xaxis: {
      tickAmount: idleTenancySeries.length < 10 ? idleTenancySeries.length : 10,
      categories: idleTenancySeries.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: i18n.t("PORTFOLIO_GRAPH_IDLE_TENANCY_RATE"),
      data: idleTenancySeries.map(function (s) {
        return ((s.possibleDays - s.occupiedDays) / s.possibleDays * 100).toFixed(1);
      })
    }, {
      name: i18n.t("PORTFOLIO_GRAPH_EVICTION_RATE"),
      data: churnRateSeries.map(function (s) {
        return s.evictions / (s.activeLeases + s.evictions) * 100;
      })
    }]
  };
};
export var formatRentAndIdleTenancyRateRentCombined = function formatRentAndIdleTenancyRateRentCombined(rentSeries, idleTenancyRateRentSeries, periodType) {
  var rent = {
    series: _toConsumableArray(rentSeries),
    translation: i18n.t("PORTFOLIO_OVERVIEW_RENT_DEVELOPMENT_GRAPH"),
    formatter: function formatter(s) {
      var _s$amount;
      return (_s$amount = s.amount) !== null && _s$amount !== void 0 ? _s$amount : 0;
    }
  };
  var idleTenancy = {
    series: _toConsumableArray(idleTenancyRateRentSeries),
    translation: i18n.t("PORTFOLIO_OVERVIEW_CHURN_RENT_DEVELOPMENT_GRAPH"),
    formatter: function formatter(s) {
      var _s$lostRent;
      return (_s$lostRent = s.lostRent) !== null && _s$lostRent !== void 0 ? _s$lostRent : 0;
    }
  };
  var longestSeries = rent.series.length > idleTenancy.series.length ? rent : idleTenancy;
  var shortestSeries = rent.series.length > idleTenancy.series.length ? idleTenancy : rent;

  // Add missing periods to the shortest series
  if (shortestSeries.series.length > 0 && longestSeries.series.length > shortestSeries.series.length) {
    longestSeries.series.forEach(function (s) {
      if (shortestSeries.series.find(function (ss) {
        return moment(ss.period).isSame(s.period);
      }) == undefined) {
        if (moment(s.period).isBefore(moment(shortestSeries.series[0].period))) {
          // Add to the beginning
          shortestSeries.series.unshift({
            period: s.period,
            amount: 0
          });
        } else {
          var indexOfLast = shortestSeries.series.findIndex(function (ss) {
            return moment(ss.period).isBefore(s.period);
          });
          // Add after the most recent period
          shortestSeries.series.splice(indexOfLast + 1, 0, {
            period: s.period,
            amount: shortestSeries.series[indexOfLast].amount
          });
        }
      }
    });
  }
  return {
    xaxis: {
      tickAmount: longestSeries.series.length < 10 ? longestSeries.series.length : 10,
      categories: longestSeries.series.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: rent.translation,
      data: rent.series.map(function (s) {
        return rent.formatter(s);
      })
    }, {
      name: idleTenancy.translation,
      data: idleTenancy.series.map(function (s) {
        return idleTenancy.formatter(s);
      })
    }]
  };
};
export var formatRentRate = function formatRentRate(series, periodType) {
  return {
    xaxis: {
      tickAmount: series.length < 10 ? series.length : 5,
      categories: series.map(function (s) {
        return s.period;
      })
    },
    xAxisFilter: PeriodTypeFilter[periodType],
    series: [{
      name: i18n.t("PORTFOLIO_RENT_DEVELOPMENT_GRAPH"),
      data: series.map(function (s) {
        return s.amount;
      })
    }]
  };
};
export var yaxisMaxForChurnAndIdleTenancy = function yaxisMaxForChurnAndIdleTenancy(eviction, idleTenancy) {
  var evictionSeries = eviction.map(function (s) {
    return s.evictions / (s.activeLeases + s.evictions) * 100;
  }.toFixed(1));
  var idleTenancySeries = idleTenancy.map(function (s) {
    return ((s.possibleDays - s.occupiedDays) / s.possibleDays * 100).toFixed(1);
  });
  var maxArray = [].concat(_toConsumableArray(evictionSeries), _toConsumableArray(idleTenancySeries));
  var yaxisMax = Math.trunc(Math.max.apply(Math, _toConsumableArray(maxArray)) + 2);
  return yaxisMax;
};